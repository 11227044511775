// paragraph
p {
	min-height: 1rem;
}

// headings
h1 {
	margin-bottom: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
	border-radius: 10px;
}

iframe {
	max-width: 100%;
}

// container
.container-fluid {
	max-width: 1920px;
}

.container-offset {
	.container-holder {
		@include media-breakpoint-up(xl) {
			padding-left: 380px;
		}
	}
}

// gallery
.gallery {
	.gallery-item {
		.gallery-link {
			border-radius: 10px;
		}
	}
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid $turquoise;
	border-radius: 0.25rem;
}

// default-card
.default-card {
	border: none;
	border-radius: 0;
	background-color: transparent;

	.card-image {
		border-radius: 10px;
	}

	.card-body {
		padding: 15px 0 0 0;

		.card-caption {
			.card-title {
				padding-right: 5px;
			}
		}
	}
}

// btn-round
a.btn-round {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 140px;
	height: 140px;
	padding: 10px;
	border-radius: 100%;
	background-color: $blue;
	border: 2px solid $blue;
	color: $yellow;
	line-height: 1.2;
	text-align: center;
	text-decoration: none !important;

	&:hover {
		background-color: $yellow;
		color: $blue;
	}
}
