// footer
.footer {
	position: relative;
	margin-top: calc(8vh + 185px);
	background-color: $white;
	font-size: 18px;

	&::before {
		content: "";
		position: absolute;
		right: 0;
		bottom: 100%;
		width: 100%;
		max-width: 565px;
		aspect-ratio: 3.05405405405/1;
		background: url('/images/visual-city-skyline.svg');
		background-size: cover;
	}

	&::after {
		content: "";
		position: absolute;
		right: 0;
		bottom: calc(100% - 75px);
		width: 100%;
		max-width: 331px;
		aspect-ratio: 1.88068181818/1;
		background: url('/images/visual-minivan.svg');
		background-size: cover;
		overflow: hidden;

		@include media-breakpoint-down(md) {
			left: 0;
			max-width: 280px;
			margin: 0 auto;
		}

		@include media-breakpoint-up(md) {
			animation: drive 25s linear infinite;
		}
	}

	@keyframes drive {
		0% {
			left: -25%;
		}

		100% {
			left: calc(100% - 331px);
		}
	}

	// footer-address
	.footer-address {
		padding: 120px 0 8vh 0;
		text-align: center;

		a {
			text-decoration: none;
		}

		// footer-logolink
		.footer-logolink {
			ul {
				justify-content: center;

				li {
					+li {
						margin-left: 10px;
					}

					a {
						display: block;
						width: 30px;
						height: 30px;
						background-color: $blue;
						border-radius: 100%;
						color: $white;
						font-size: 18px;
						line-height: 30px;
						text-align: center;

						&:hover {
							background-color: $turquoise;
						}
					}
				}
			}
		}
	}

	// footer-outro
	.footer-outro {
		text-align: center;

		img {
			max-width: 280px;
			border-radius: 100%;
		}
	}

	// footer-partners
	.footer-partners {
		margin: 8vh 0;

		ul {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				margin: 15px;

				img {
					border-radius: 0;
				}
			}
		}
	}

	// footer-copyright
	.footer-copyright {
		padding-bottom: 15px;

		ul {
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			@include media-breakpoint-up(md) {
				flex-direction: row;
			}

			margin: 0;
			padding: 0;
			list-style: none;

			li {
				@include media-breakpoint-up(md) {
					margin: 0 15px;
				}

				color: $turquoise;
				font-size: 14px;

				a {
					color: $turquoise;
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}

&.home {
	.footer {
		margin-top: 0;
	}
}
