// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 8vh 0;
	}
}

.lead-section+.content-section,
.lead-section+.bundle-overview-section,
.lead-section+.bundle-detail-section {
	margin-top: calc(-8vh + 30px);
}

// lead-section
// =========================================================================
.lead-section {
	margin: 8vh 0;

	.container-one-column {
		.container-holder {
			@include media-breakpoint-up(xl) {
				max-width: 950px;
				margin: 0 auto;
				text-align: center;
			}
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;

		@include media-breakpoint-up(xl) {
			margin-top: -35px;
		}
	}

	.info {

		// subtitle
		.subtitle {
			h5:empty {
				display: none;
			}
		}

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}
}

// intro-collection-section
// =========================================================================
.intro-collection-section {
	padding: 8vh 0;
	background-color: $turquoise;

	// collection
	.collection {
		&.grid {
			.grid-items {
				.item {
					.card {
						border: none;
						border-radius: 0;
						background-color: transparent;

						.card-image {
							border-radius: 10px;
						}

						.card-body {
							padding: 25px 0 0 0;
							text-align: center;

							.card-caption {
								.card-title {
									color: $yellow;
									@include font-size($h2-font-size);

									@include media-breakpoint-up(xl) {
										font-size: 38px;
									}
								}

								.card-description {
									max-width: 360px;
									margin-left: auto;
									margin-right: auto;

									.list-icons {
										display: none;
									}
								}
							}

							.card-buttons {
								.card-btn {
									&:hover {
										border-color: $yellow;
										background-color: $yellow;
										color: $black;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	// link
	a.link {
		margin: 30px auto 0 auto;
	}
}

// featured-section
// =========================================================================
.featured-section {
	position: relative;
	padding: 8vh 0 116px 0;
	background-color: $yellow;

	&::after {
		content: "";
		position: absolute;
		left: 15px;
		bottom: -60px;
		width: 100%;
		max-width: 275px;
		aspect-ratio: 1.5625/1;
		background: url('/images/visual-jeu-de-boules.svg');
		background-size: cover;

		@include media-breakpoint-down(lg) {
			right: 15px;
			margin-left: auto;
			margin-right: auto;
		}

		@include media-breakpoint-up(xl) {
			left: calc((100vw / 2 - 555px));
		}
	}

	h4 {
		color: $blue;
	}

	ul.global-usps {
		margin: 30px 0;
		align-items: center;
		justify-content: center;

		@include media-breakpoint-up(xl) {
			justify-content: space-between;
		}

		li {
			a {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 140px;
				height: 140px;
				margin: 10px;
				padding: 10px;
				border-radius: 100%;
				background-color: $blue;
				border: 2px solid $blue;
				color: $yellow;
				line-height: 1.2;
				text-align: center;
				text-decoration: none !important;

				&[href]:hover {
					background-color: $yellow;
					color: $blue;
				}
			}
		}
	}
}

// tiles-collection-section
// =========================================================================
.tiles-collection-section {
	padding-top: calc(6vh + 60px);
	padding-bottom: 185px;
	background-color: $turquoise;

	// collection
	.collection {
		margin: 30px 0 6vh 0;

		&.grid {
			.grid-items {
				//margin-bottom: -45px;

				.item {
					//margin-bottom: 45px;

					.card {
						border: none;
						background: none;
						border-radius: 0;

						.card-image {
							border-radius: 10px;
						}

						.card-body {
							padding: 5px 0 0 0;

							.card-caption {
								text-align: center;

								.card-title {
									font-size: 16px;
									font-weight: 400;
								}

								.card-subtitle,
								.card-description {
									display: none;
								}
							}
						}
					}
				}
			}
		}
	}

	// buttons
	.buttons {
		flex-flow: row wrap !important;
		justify-content: center;

		.link+.link {
			margin-left: 30px;
		}
	}

}

// content-section
// =========================================================================
.content-section {
	margin: 8vh 0;
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 8vh 0;

	// collection
	.collection {
		&.grid {
			.grid-items {
				.item {
					margin-bottom: 45px;
				}
			}
		}

		.card {
			@extend .default-card !optional;
		}

		&.accommodation-overview {
			.card {
				.card-title-link {
					min-height: 61.6px;
				}

				.card-description-content:not(:empty) {
					min-height: 38.4px;
				}
			}
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 8vh 0;
}

&.accommodation-category-detail {
	.bundle-detail-section {
		.column {
			&.one {
				@include make-col(12);
				padding-right: 15px !important;
			}

			&.two {
				display: none;
			}
		}
	}
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-8vh + 30px);
	margin-bottom: calc(-8vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 8vh 0;

	h2 {
		margin-bottom: 30px;
	}
}
