// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 75vh;
	min-height: 300px;
	max-height: 540px;

	@include media-breakpoint-up(xl) {
		height: calc(100vh - 350px);
		min-height: 540px;
		max-height: 1080px;
	}
}

// eyecatcher
.eyecatcher {
	position: relative;

	.owl-carousel {
		.item {
			.owl-container {
				z-index: 1;
				position: relative;

				.owl-caption {
					.owl-subtitle {
						color: $white;
					}

					.owl-description {
						max-width: 750px;
						margin: 0 auto 1rem auto;
					}
				}
			}
		}

		.owl-nav {
			display: none;
		}

		.owl-dots {
			.owl-dot {
				span {
					width: 20px;
					height: 20px;
					border-color: $yellow;
					border-radius: 100%;
					box-shadow: none;
				}

				&:hover,
				&.active {
					span {
						background-color: $yellow;
					}
				}
			}
		}

	}
}
