// header
.header {

	&.sticky {
		.header-top {
			z-index: 998;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			box-shadow: $shadow;
		}
	}

	// header-top
	.header-top {
		padding: 12px 0;
		background-color: $yellow;

		@include media-breakpoint-up(xl) {
			padding-left: 25px;
			padding-right: 25px;
		}

		.container-holder {
			>.column {
				align-items: center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		margin-right: auto;

		.navbar-toggler {
			display: flex;
			align-items: center;
			padding: 0;
			border: none;
			color: $blue;
			font-size: 16px;
			font-weight: 700;
			text-transform: uppercase;
			transition: 0.5s;

			i {
				width: 30px;
				font-size: 20px;
				text-align: center;
			}

			&[aria-expanded="true"] {
				i {
					&::before {
						content: "\f00d";
					}
				}
			}

			&:hover {
				color: $turquoise;
			}
		}
	}

	// logo
	.logo {
		z-index: 100;
		position: absolute;
		top: 0;
		max-width: 100px;
		transform: rotate(-5deg);
		transition: 0.5s;

		@include media-breakpoint-down(lg) {
			left: 100px;
		}

		@include media-breakpoint-up(xl) {
			max-width: 350px;
			top: 75px
		}

		a {
			display: block;

			img {
				width: 100%;
				border-radius: 0;
			}
		}
	}

	&.sticky {
		.logo {
			@include media-breakpoint-up(xl) {
				max-width: 175px;
				top: 0;
			}
		}
	}

	// menu
	.menu {
		font-family: $font-family-secondary;

		@include media-breakpoint-down(lg) {
			order: 5;

			.navbar-nav {
				margin-top: 15px;

				>.nav-item {
					font-size: 16px;
					font-weight: 700;

					>.nav-link {
						color: $blue;

						&:hover {
							color: $turquoise;
						}
					}

					&.active {
						>.nav-link {
							color: $turquoise;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 0 0 0 20px;
						padding: 0;
						border: none;
						box-shadow: none;
						background: none;

						.nav-item {
							font-size: 15px;

							.nav-link {
								color: $blue;

								&:hover {
									color: $turquoise;
								}
							}

							&.active {
								>.nav-link {
									color: $turquoise;
								}
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			justify-content: center;
			padding-left: 145px;

			.navbar-nav {
				align-items: center;

				>.nav-item {
					margin: 0 5px;
					font-size: 16px;
					font-weight: 700;

					>.nav-link {
						color: $blue;

						&:hover {
							color: $turquoise;
						}
					}

					&.active {
						>.nav-link {
							color: $turquoise;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						padding: 10px;

						.nav-item {
							font-size: 16px;
							line-height: 1.2;
							white-space: nowrap;
							text-transform: none;

							.nav-link {
								color: $blue;

								&:hover {
									color: $turquoise;
								}
							}

							&.active {
								.nav-link {
									color: $turquoise;
								}
							}
						}
					}
				}
			}
		}
	}

	// site-switcher
	.site-switcher {
		margin-right: 90px;
	}

	// global-book-button
	div.global-book-button {
		position: absolute;
		top: 0;
		right: 15px;

		ul {
			li {
				a {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 75px;
					height: 75px;
					border-radius: 100%;
					background-color: $blue;
					border: 2px solid $blue;
					color: $yellow;
					line-height: 1.1;
					text-align: center;
					text-decoration: none;

					&:hover {
						background-color: $yellow;
						color: $blue;
					}
				}
			}
		}
	}

	// header-bottom
	.header-bottom {
		padding: 30px 0;
		background-color: $turquoise;

		@include media-breakpoint-down(lg) {
			padding-top: 60px;
		}

		@include media-breakpoint-up(xl) {
			padding-left: 25px;
			padding-right: 25px;
		}

		.container-holder {
			align-items: center;
			margin-bottom: 0;

			>.column {
				margin-bottom: 0;

				&.one {
					padding-right: 15px !important;
					@include make-col(12);

					@include media-breakpoint-up(xl) {
						@include make-col(4);
					}
				}

				&.two {
					padding-left: 15px !important;
					@include make-col(12);

					@include media-breakpoint-up(xl) {
						@include make-col(8);
					}
				}
			}

			.heading {
				@extend .h1;
				margin-bottom: 0;
				color: $white;
				text-shadow: 2px 2px 0 rgba($black, 1);

				@include media-breakpoint-down(lg) {
					text-align: center;
				}

				@include media-breakpoint-up(xl) {
					text-shadow: 4px 4px 0 rgba($black, 1);
				}
			}
		}

		// mini-search-book
		.mini-search-book {
			@include media-breakpoint-down(lg) {
				margin-bottom: 30px;
			}
		}
	}
}

&:not(.home) {
	.header {
		.logo {
			@include media-breakpoint-up(xl) {
				max-width: 175px;
				top: 0;
			}
		}
	}
}

&.navbar-collapse-active,
&.sticky {
	.header {
		.logo {
			@include media-breakpoint-down(lg) {
				opacity: 0;
				visibility: hidden;
			}
		}
	}
}
